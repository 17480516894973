import { template as template_fe11e8f9fce645deb6b156cd36e3807c } from "@ember/template-compiler";
const FKText = template_fe11e8f9fce645deb6b156cd36e3807c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
