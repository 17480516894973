import { template as template_e9058c4bacda46acab8e8056d57a70a3 } from "@ember/template-compiler";
const FKLabel = template_e9058c4bacda46acab8e8056d57a70a3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
